import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import WistiaPopoverVideo from '../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../components/FreeTrialSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Honey CRM rebrands to GlassHive"
                description="Honey CRM has recently changed its name to GlassHive. Check it out!"
            />
            <div className="lead-scrubbing-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                Welcome to GlassHive!
                            </h1>
                            <p className="hero-subtext white-text">
                                Honey CRM rebrands to a new name
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'d06m82n41m'}>
                                <img
                                    className="thumbnail"
                                    src="https://embedwistia-a.akamaihd.net/deliveries/795dc20def6f567b34161ee95dfce142.jpg?wistia_video_embed_container-d06m82n41m-video-thumbnail=1&amp;image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=1e71e7e0"
                                    alt="Automatic lead scrubbing video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Say hello to GlassHive
                            </h4>
                            <p>
                                Honey CRM has recently rebranded and will now be
                                known as GlassHive. We’re excited for this
                                transition as it aligns with our efforts to
                                create transparency in your sales and marketing
                                efforts.
                            </p>
                            <p>
                                Don’t worry, there are still hard working honey
                                bees in the hive to ensure your sales and
                                marketing is simple & fun!
                            </p>
                            <p>
                                Our name change isn’t the only thing changing --
                                our innovative team is constantly releasing
                                features and updates to enhance your software
                                experience.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Get started with GlassHive
                            </h4>
                            <p>
                                Not ready to commit? Don’t worry. Give GlassHive
                                a try for free.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Start Free Trial" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
